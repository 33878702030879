@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('./assets/ProximaNova/ProximaNova-Semibold.eot');
  src: local('./assets/ProximaNova/Proxima Nova Semibold'),
    local('ProximaNova-Semibold'),
    url('./assets/ProximaNova/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
    url('./assets/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./assets/ProximaNova/ProximaNova-Regular.eot');
  src: local('./assets/ProximaNova/Proxima Nova Regular'),
    local('ProximaNova-Regular'),
    url('./assets/ProximaNova/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/ProximaNova/ProximaNova-Regular.woff') format('woff'),
    url('./assets/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Proxima Nova Rg', sans-serif;
  background-color: rgba(10, 0, 70, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/fon.png');
  background-position-x: top;
  /* background-position-y: -100; */
  background-repeat: no-repeat;
  background-size: auto;
  background-size: cover;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  scrollbar-width: thin;
}

/*::-webkit-scrollbar {*/
/*  width: 14px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*  background: #f1f1f1;*/
/*  background: transparent;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*  background: rgb(78 78 78 / 80%);*/
/*  !*border-radius: 3px;*!*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*  background: #555;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-text {
  background-color: #fff;
  background-image: linear-gradient(
    180deg,
    rgba(12, 0, 73, 0) 0%,
    #0c0049 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
